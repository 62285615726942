<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('license_management.application') }} {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:searchBody>
         <b-row>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <ValidationProvider name="Status" vid="status">
                <b-form-group
                  label-for="status"
                  slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                  {{$t('globalTrans.status')}}
                </template>
                <b-form-select
                  plain
                  v-model="search.status"
                  :options="statusList"
                  id="status_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                  <template v-slot:first>
                    <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <b-form-group
                label-for="application_id"
                >
                <template v-slot:label>
                    {{ $t('globalTrans.application_id') }}
                </template>
                <b-form-input
                    id="application_id"
                    v-model="search.application_id"
                    >
                    </b-form-input>
                </b-form-group>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <b-form-group
                label-for="from_date"
                >
                <template v-slot:label>
                    {{ $t('globalTrans.from_date') }}
                </template>
                <date-picker
                  id="from_date"
                  v-model="search.from_date"
                  class="form-control"
                  :placeholder="$t('globalTrans.select_date')"
                >
                </date-picker>
                </b-form-group>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <b-form-group
                label-for="to_date"
                >
                <template v-slot:label>
                    {{ $t('globalTrans.to_date') }}
                </template>
                <date-picker
                  id="from_date"
                  v-model="search.to_date"
                  class="form-control"
                  :placeholder="$t('globalTrans.select')"
                >
                </date-picker>
                </b-form-group>
            </b-col>
            <b-col sm="4" class="mt-4">
                <b-button size="sm" variant="primary" @click="searchData">
                <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                </b-button>
            </b-col>
        </b-row>
      </template>
      <!-- search section end -->
    </card>

    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('license_management.application_list') }}</h4>
      </template>
      <template v-slot:headerAction>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <select-column-check v-on:editMethodInParent="editMethodInParent" :labelData="labelData" :search="search" :columns="columns" />
            <div class="row">
              <div class="col-md-4">
                <b-form-checkbox
                class="mb-2"
                  v-model="search.select"
                  @change="checkAll($event)"
                > <span class="badge badge-success">{{ $t('license_management.check_all') }}</span>
                </b-form-checkbox>
              </div>
              <div class="col-md-8 text-right">
                {{ $t('license_management.selected_applications') }} :
                <span v-if="applications.length">
                  <span v-for="(app, index) in applications" :key="index" class="ml-2 badge badge-success"> {{ app.application_id }}</span>
                </span>
                <span v-else>({{ $t('license_management.empty') }})</span>
                <button v-if="applications.length" style="padding: 0px 6px;" class="btn btn-success btn-sm ml-3" variant=" iq-bg-success mr-1" :title="$t('globalTrans.forward')" @click="clickForward"><i class="ri-chat-forward-line"></i></button>
              </div>
            </div>
          <b-row>
            <b-col md="12" class="table-responsive">
              <slot v-if="columns.filter(item => item.show === '1').length > 0">
                <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                  <template v-slot:cell(select)="data">
                      <b-form-checkbox v-if="data.item.status === 2"
                        v-model="data.item.select"
                        @change="checkApplication(data.item)"
                      >
                      </b-form-checkbox>
                    </template>
                  <template v-slot:cell(serial)="data">
                    {{ $n(data.item.serial + pagination.slOffset) }}
                  </template>
                  <template v-slot:cell(application_id)="data">
                    {{ data.item.application_id }}
                  </template>
                  <template v-slot:cell(date)="data">
                    {{ data.item.created_at | dateFormat }}
                  </template>
                  <template v-slot:cell(applicant_mobile)="data">
                    {{ data.item.applicant_mobile | mobileNumber }}
                  </template>
                  <template v-slot:cell(status)="data">
                    <span class="badge badge-primary" v-if="data.item.status == 2">{{$t('lrcpnAcidLicense.pending')}}</span>
                    <span class="badge badge-info" v-if="data.item.status == 3">{{$t('lrcpnAcidLicense.forwared')}}</span>
                    <span class="badge badge-secondary" v-if="data.item.status == 4">{{$t('lrcpnAcidLicense.inspection')}}</span>
                    <span class="badge badge-warning" v-if="data.item.status == 5">{{$t('lrcpnAcidLicense.inspected')}}</span>
                    <span class="badge badge-warning" v-if="data.item.status == 6">{{$t('lrcpnAcidLicense.approval')}}</span>
                    <span class="badge badge-success" v-if="data.item.status == 7">{{$t('lrcpnAcidLicense.approved')}}</span>
                    <span class="badge badge-danger" v-if="data.item.status == 8">{{$t('lrcpnAcidLicense.rejected')}}</span>
                  </template>
                  <template v-slot:cell(action)="data">
                    <!-- view -->
                    <router-link class="action-btn btn-warning view" :title="$t('globalTrans.view')" :to="{ name: 'lrcpn_service.acid_import.application_details', params: { id: data.item.id } }"><i class="ri-eye-fill"></i></router-link>
                    <!-- forward -->
                    <router-link v-if="data.item.status === 2" class="action-btn active view" variant=" iq-bg-success mr-1" :title="$t('globalTrans.forward')" :to="`/lrcpn-service/acid-import/forward?stepId=${parseInt(3)}&applications=${JSON.stringify([data.item.id])}`"><i class="ri-send-plane-fill"></i></router-link>
                    <!-- reject -->
                    <button @click="setReject(data.item.id)" v-if="data.item.status < 7" v-b-modal.modal-form class="btn moc-action-btn moc-reject-btn btn-secondary" variant=" iq-bg-success mr-1" :title="$t('license_management.resubmit_reject')" ><i class="ri-close-circle-line"></i></button>
                  </template>
                </b-table>
              </slot>
              <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                <b-pagination
                  v-model="pagination.currentPage"
                  :perPage="search.limit"
                  :total-rows="pagination.totalRows"
                  @input="searchData"
                />
               </div>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
    <!-- reject, resubmit modal -->
    <b-modal id="modal-form" size="lg" :title="formTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Reject :id="editItemId"/>
    </b-modal>
  </div>
</template>
<script>
import RestApi, { lrcpnServiceBaseUrl } from '@/config/api_config'
import { acidImportApplicationListApi } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import Reject from '../../components/Reject.vue'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Reject
  },
  data () {
    return {
      lrcpnServiceBaseUrl: lrcpnServiceBaseUrl,
      sortBy: '',
      sortDesc: true,
      sortDirection: 'desc',
      applications: [],
      search: {
       application_id: '',
       from_date: '',
       to_date: '',
       status: 2,
       limit: 10
      },
      labelData: [
        { labels: 'globalTrans.select', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '5%' } },
        { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '5%' } },
        { labels: 'globalTrans.date', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '5%' } },
        { labels: 'globalTrans.application_id', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '10%' } },
        { labels: 'dealer.applicant_name', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '10%' } },
        { labels: 'dealer.mobile', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '10%' } },
        { labels: 'globalTrans.status', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 7, thStyle: { width: '10%' } },
        { labels: 'globalTrans.action', class: 'text-left', sortable: true, stickyColumn: false, show: '1', order: 8, thStyle: { width: '10%' } }
      ],
      actions: {
        edit: false,
        details: false,
        toogle: false,
        delete: false
      }
    }
  },
  created () {
    this.loadData()
  },
  mounted () {
  },
  watch: {
    'search.limit': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    }
  },
  computed: {
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
      })
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'select' },
          { key: 'serial' },
          { key: 'date' },
          { key: 'application_id' },
          { key: 'applicant_name_bn' },
          { key: 'applicant_mobile' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'select' },
          { key: 'serial' },
          { key: 'date' },
          { key: 'application_id' },
          { key: 'applicant_name' },
          { key: 'applicant_mobile' },
          { key: 'status' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    statusList () {
      return [
        { value: 2, text: this.$i18n.locale === 'en' ? 'Pending' : 'অপেক্ষমাণ', text_en: 'Pending', text_bn: 'অপেক্ষমাণ' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Forwarded' : 'ফরোয়ার্ড', text_en: 'Forwarded', text_bn: 'ফরোয়ার্ড' },
        { value: 4, text: this.$i18n.locale === 'en' ? 'Inspection' : 'পরিদর্শন', text_en: 'Inspection', text_bn: 'সুপারিশ' },
        { value: 5, text: this.$i18n.locale === 'en' ? 'Approval' : 'অনুমোদন', text_en: 'Approval', text_bn: 'সুপারিশকৃত' },
        { value: 6, text: this.$i18n.locale === 'en' ? 'Approved' : 'অনুমোদিত', text_en: 'Approved', text_bn: 'অনুমোদিত' },
        { value: 7, text: this.$i18n.locale === 'en' ? 'Rejected' : 'প্রত্যাখ্যাত', text_en: 'Rejected', text_bn: 'প্রত্যাখ্যাত' }
      ]
    },
    formTitle () {
      return this.$t('license_management.reject')
    }
  },
  methods: {
    clickForward () {
      const appIds = this.applications.map(item => {
        return item.id
      })
      this.$router.push({
        path: '/lrcpn-service/acid-import/forward?stepId=3',
        query: { applications: JSON.stringify(appIds) }
      })
    },
    checkApplication (value) {
      if (value.select) {
        this.applications.push({
          application_id: value.application_id,
          id: value.id
        })
      } else {
        this.applications = this.applications.filter(item => {
          return item.application_id !== value.application_id
        })
      }
    },
    checkAll (value) {
      if (value) {
        this.listData.map(item => {
          return Object.assign(item, { select: true })
        })
        const applications = this.listData.filter(item => item.status === 2).map(item => {
          return { application_id: item.application_id, id: item.id }
        })
        this.applications = applications
      } else {
        this.applications = []
        this.listData.map(item => {
          return Object.assign(item, { select: false })
        })
      }
    },
    editMethodInParent (item, type) {
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      const params = Object.assign({}, this.search, {
         page: this.pagination.currentPage,
         per_page: this.search.limit
      })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(lrcpnServiceBaseUrl, acidImportApplicationListApi, params)
      if (result.success) {
        const listData = result.data.data.map((item, index) => {
          return Object.assign({}, item, { serial: index, select: 0 })
        })
        this.$store.dispatch('setList', listData)
        this.paginationData(result.data, this.search.limit)
      } else {
        this.$store.dispatch('setList', [])
        this.paginationData([])
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    setReject (id) {
      this.editItemId = id
    }
  }
}
</script>

<style>
.btn-success {
  background-color: var(--success) !important;
  border-color: var(--success);
}
</style>
